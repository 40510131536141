import React, { useState, useEffect, useMemo } from 'react';
import style from '../styles/swap.module.css';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, Dropdown, Button } from 'react-bootstrap';
import { useWeb3React } from '@web3-react/core';
import { message } from 'antd';
import Connect from './Connect';
import useAuth from 'hooks/useAuth';
import { getDexiBalance } from '../services/dexiStakeService';
import { getDisplayBalance } from 'helpers/tokenUtils';

const NETWORKMETADATA: any = {
  137: {
    chainId: '0x89',
    chainName: 'Polygon',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com'],
  },
};
const mainNetworks: any = {
  137: {
    chainId: 137,
    name: 'Polygon',
    symbol: 'MATIC',
    decimals: 18,
    rpcUrls: 'https://polygon-rpc.com',
    blockExplorerUrls: 'https://polygonscan.com',
    logo: 'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
  },
};

export default function Header() {
  const { connector, library, chainId, account, active, activate, deactivate } = useWeb3React();
  const [selectedNetwork, setSelectedNetwork] = useState<any>(chainId?? 0);
  const isLoggedin = account && active;
  const { login, logout } = useAuth();

  const [showConnectModal, setShowConnectModal] = useState(false);

  const showConnectModalMemo = useMemo(() => {
    return showConnectModal;
  }, [showConnectModal]);

  const addNetwork = async (metadata: any) => {
    await window?.ethereum
      .request({
        method: 'wallet_addEthereumChain',
        params: [metadata],
      })
      .catch();

    message.success('Added Network');
  };

  const switchNetwork = async (chainId: any, active: any) => {
    console.log('chainId : ', chainId);
    if (!active) {
      // chainId in localStorage allows to switch network even if wallet is not connected
      // or there is no wallet at all
      localStorage.setItem('selected_network', chainId);
      document.location.reload();
      return;
    }

    try {
      const chainIdHex = '0x' + chainId.toString(16);
      await window?.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdHex }],
      });
      message.success(`Connected to ${mainNetworks[chainId].name} network`);
      setSelectedNetwork(chainId);
      localStorage.setItem('selected_network', chainId);
      document.location.reload();
    } catch (ex: any) {
      // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
      // This error code indicates that the chain has not been added to MetaMask.
      // 4001 error means user has denied the request
      // If the error code is not 4001, then we need to add the network
      console.log('ex?.code  : ', ex?.code);
      if (ex?.code !== 4001) {
        console.log('ex?.code  : ', NETWORKMETADATA[chainId]);
        return await addNetwork(NETWORKMETADATA[chainId]);
      }

      console.error('error', ex);
    }
  };

  useEffect(() => {
    if (account && chainId != 137) {
      switchNetwork(137, true);
    }
  }, [account, library, chainId]);

  return (
    <>
      {isLoggedin ? (
        <>
          <div
            onClick={() => {
              logout();
            }}
          >
            <span>Disconnect</span>
          </div>
        </>
      ) : (
        <>
          <span
            onClick={() => {
              setShowConnectModal(true);
            }}
          >
            Connect wallet
          </span>
        </>
      )}
      {showConnectModal ? (
        <>
          <Connect
            onWalletSelect={(walletName: string) => {
              if (walletName == 'WalletConnect') {
                login(2);
              } else if (walletName == 'Metamask') {
                login(1);
              }
            }}
            onClose={() => {
              setShowConnectModal(false);
            }}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
}
