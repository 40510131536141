import { FC, ReactNode } from "react";
import { useMediaQuery } from "usehooks-ts";
import { group3239, path6219, path6239, path7219, path7239 } from ".";

interface IButton {
  onClick?: Function;
  active?: boolean;
  children?: ReactNode;
}

export const PadButton: FC<IButton> = (props) => {
  const { onClick = () => console.log("Button click"), active } = props;
  const isDesktop = useMediaQuery("(min-width:1024px)");

  if (isDesktop) {
    return (
      <>
        {active ? (
          <div className="group-20">
            <div className="group-3-1">
              <div className="overlap-group-5">
                <div
                  className="staking-button group-64"
                  onClick={() => onClick()}
                >
                  <div className="staking-dexi oxanium-bold-resolution-blue-21px">
                    {props.children}
                  </div>
                  <div className="rectangle-13-3"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="group-21">
            <div className="staking-button group-3-2" onClick={() => onClick()}>
              <div className="overlap-group1-1">
                <div className="rectangle-13-4"></div>
                <div className="staking-usdc oxanium-light-white-19px">
                  {props.children}
                </div>
                <div className="group-2">
                  <div className="group-2">
                    <div className="rectangle-374-1"></div>
                    <div className="rectangle-375-1"></div>
                  </div>
                  <img className="path-6-1" src={path6219} />
                  <img className="path-7-1" src={path7219} />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {active ? (
        <div className="staking-button group-20-6" onClick={() => onClick()}>
          <div className="group-3-34">
            <div className="staking-dexi-3">{props.children}</div>
          </div>
        </div>
      ) : (
        <div className="staking-button group-21-6" onClick={() => onClick()}>
          <div className="group-container-38">
            <div className="group-3-35">
              <div className="staking-usdc-6">{props.children}</div>
            </div>
            <div
              className="group-32-4"
              style={{ backgroundImage: `url(${group3239})` }}
            >
              <img className="path-6-21" src={path6239} alt="Path 6" />
              <img className="path-7-21" src={path7239} alt="Path 7" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
