import { FC } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { staske19, stakeTokens19 } from '.';

interface IButton {
  onClick?: Function;
  children: React.ReactNode;
  disabled?: boolean;
}
export const PadMainButton: FC<IButton> = (props) => {
  const isDesktop = useMediaQuery('(min-width:800px)');

  if (isDesktop)
    return (
      <div
        className={`staking-main-button${props.disabled ? '-disabled' : ''} staske`}
        style={{ backgroundImage: `url(${staske19})` }}
        onClick={() => {
          if (!props.disabled) {
            props.onClick?.();
          }
        }}
      >
        <div
          style={{
            position: 'initial',
            fontSize: '28px',
            lineHeight: '28px',
          }}
          className="stake-tokens-6 oxanium-bold-resolution-blue-21px"
        >
          {props.children}
        </div>
      </div>
    );

  return (
    <div className="staske-mobile">
      <div
        onClick={() => {
          if (!props.disabled) {
            props.onClick?.();
          }
        }}
        className={`staking-main-mobile-button${
          props.disabled ? '-disabled' : ''
        }  stake-tokens-6 oxanium-bold-resolution-blue-21px`}
      >
        {props.children}
      </div>
    </div>
  );
};
