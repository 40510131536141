import { CoinGeckoChart } from 'components/CoinGeckoChart';
import Group50 from 'components/Group50';
import Group51 from 'components/Group51';
import Group52 from 'components/Group52';
import Group53 from 'components/Group53';
import Header from 'components/Header';
import { InfoCard } from 'components/InfoCard';
import { SingleCard } from 'components/SingleCard';
import { PadCard } from 'components/PadCard';
import { dexi19, dexioprotocolVerbiage219, ConnectButton } from 'components/ui';
import { useAppStore } from 'hooks/useAppStore';
import { useDexi } from 'hooks/useDexi';

const Home = () => {
  useDexi();
  const { totalCommitted } = useAppStore();
  return (
    <div className="home">
      <div className="new-home">
        <div className="new-header">
          <div>
            <div className="overlap-group8">
              <div className="staking orbitron-medium-white-19px">{'Launchpad'}</div>
              <img className="dexi" src={dexi19} alt="DEXI" />
              <img className="dexioprotocol-verbiage-2" src={dexioprotocolVerbiage219} alt="Dexioprotocol Verbiage 2" />
            </div>
          </div>
          <div className="new-header--right">
            <ConnectButton>
              <Header />
            </ConnectButton>
          </div>
        </div>
        <div className="group-63">
          <SingleCard text={`${totalCommitted?.toFixed(0) ?? '--'} DEXI`}>
            <Group53 text="Total Committed" />
          </SingleCard>
          <SingleCard text={`15 DEXI`}>
            <Group53 text="Launch Price" />
          </SingleCard>
        </div>

        <div className="overlap-group-container-1">
          <PadCard stakeToken="DEXI" />
        </div>
      </div>
    </div>
  );
};

export { Home };
