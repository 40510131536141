import { useCallback, useEffect, useMemo, useState } from 'react';

import { BigNumber } from 'ethers';
import { getDexiBalance } from 'services/dexiStakeService';
import { pooling } from 'helpers/commons';
import { getDisplayBalance } from 'helpers/tokenUtils';
import { useWeb3React } from '@web3-react/core';
import { setContractData } from 'store/slices/contracts';
import { useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';

const DECIMALS = 9;
const FRACTION_DIGITS = 2;

const useDexi = () => {
  const [balance, setBalance] = useState<BigNumber | null>(null);
  const dispatch = useDispatch();

  const dexiBalance = useMemo(() => {
    if (balance === null) return null;
    return getDisplayBalance({ balance, decimals: DECIMALS, fractionDigits: FRACTION_DIGITS });
  }, [balance]);

  const { connector, library, chainId, account, active, activate, deactivate } = useWeb3React();
  const isLoggedin = !!account && active;

  useInterval(() => {
    if (isLoggedin) {
      getDexiBalance(account, library)
        .then((r: any) => {
          const dexiBalance = getDisplayBalance({ balance: r, decimals: DECIMALS, fractionDigits: FRACTION_DIGITS });
          dispatch(setContractData({ ownBalance: dexiBalance }));
        })
        .catch((e) => console.error(e));
    }
  }, 2000);
};

export { useDexi };
