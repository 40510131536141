import {
  getUserInfoPad,
  getTotalUsers,
  getTotalCommited,
  getRewardAmount,
  getEndTime,
  getStartTime,
  getFinalized,
} from 'services/dexiStakeService';
import { getDisplayBalance } from 'helpers/tokenUtils';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  setFinalized,
  setRewardAmount,
  setStakers,
  setStartTime,
  setTotalFundsCommited,
  setUnlockTime,
  setUserInfo,
} from 'store/slices/contracts';
import { useInterval } from 'usehooks-ts';

const useDexiStakeContract = () => {
  const { connector, library, chainId, account, active, activate, deactivate } = useWeb3React();
  const isLoggedin = !!account && active;
  const dispatch = useDispatch();
  const activeContract = useSelector((state: RootState) => state.contract.activeContract);

  useInterval(() => {
    if (isLoggedin) {
      getUserInfoPad(account, library)
        .then((r: any) => {
          dispatch(
            setUserInfo({
              amount: Number(getDisplayBalance({ balance: r.amount, fractionDigits: 2, decimals: 9 })),
              claimedTokens: r.claimedTokens,
            }),
          );
        })
        .catch((e) => console.error(e));
      getTotalUsers(library)
        .then((r: any) =>
          dispatch(setStakers(Number(getDisplayBalance({ balance: r, fractionDigits: 2, decimals: 9 })))),
        )
        .catch((e) => console.error(e));
      getTotalCommited(library)
        .then((r: any) =>
          dispatch(setTotalFundsCommited(Number(getDisplayBalance({ balance: r, fractionDigits: 2, decimals: 9 })))),
        )
        .catch((e) => console.error(e));
      getRewardAmount(account, library)
        .then((r: any) =>
          dispatch(setRewardAmount(Number(getDisplayBalance({ balance: r, fractionDigits: 2, decimals: 9 })))),
        )
        .catch((e) => console.error(e));
      getEndTime(library)
        .then((r: any) => dispatch(setUnlockTime(Number(r))))
        .catch((e) => console.error(e));
      getStartTime(library)
        .then((r: any) => dispatch(setStartTime(Number(r))))
        .catch((e) => console.error(e));
      getFinalized(library)
        .then((r: any) => dispatch(setFinalized(r)))
        .catch((e) => console.error(e));
    }
  }, 2000);
};

export { useDexiStakeContract };
