import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { useCallback, useEffect, useState } from 'react';

import ERC20 from '../services/ERC20';

const useAllowance = (token: ERC20 | null, spender: string, pendingApproval?: boolean) => {
  const [allowance, setAllowance] = useState<BigNumber | null>(null);
  const { account } = useWeb3React();

  const fetchAllowance = useCallback(async () => {
    if (!account || !token) {
      return setAllowance(BigNumber.from(0));
    }
    const allowance = await token.allowance(account, spender);
    setAllowance(allowance);
  }, [account, spender, token]);

  useEffect(() => {
    if (account && spender && token) {
      fetchAllowance().catch((err) => console.error(`Failed to fetch allowance: ${err.stack}`));
    }
  }, [account, spender, token, pendingApproval, fetchAllowance]);

  return allowance;
};

export default useAllowance;
