import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Interface } from 'ethers/lib/utils';
import { UserInfoLaunchpad } from 'services/dexiStakeService';
import { getDisplayBalance } from 'helpers/tokenUtils';
import { BigNumber } from 'ethers';

interface IUserInfoLaunchpad {
  amount: number;
  claimedTokens: boolean;
}

interface ISingleContractData {
  contractName?: string;
  unlockTime?: number;
  startTime?: number;
  tokenName?: string;
  ownBalance?: string;
  userInfo?: IUserInfoLaunchpad;
  stakers?: number;
  totalCommitted?: number;
  rewardAmount?: number;
  finalized?: boolean;
}
export interface IContractState {
  activeContract: string;
  list: Record<string, ISingleContractData>;
}

const initialState: IContractState = {
  activeContract: 'DEXIGOVPAD',
  list: {
    DEXIGOVPAD: { contractName: 'DEXIGOVPAD' },
  },
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContract: (state, action: PayloadAction<string>) => {
      state.activeContract = action.payload;
    },
    setContractData: (state, action: PayloadAction<ISingleContractData>) => {
      const key = state.activeContract;
      state.list[key] = { ...state.list[key], ...action.payload };
      state.list[key].contractName = key;
    },
    setStakers: (state, action: PayloadAction<number>) => {
      const key = state.activeContract;
      state.list[key].stakers = action.payload;
    },
    setTotalFundsCommited: (state, action: PayloadAction<number>) => {
      const key = state.activeContract;
      state.list[key].totalCommitted = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<IUserInfoLaunchpad>) => {
      const key = state.activeContract;
      state.list[key].userInfo = action.payload;
    },

    setRewardAmount: (state, action: PayloadAction<number>) => {
      const key = state.activeContract;
      state.list[key].rewardAmount = action.payload;
    },

    setUnlockTime: (state, action: PayloadAction<number>) => {
      const key = state.activeContract;
      state.list[key].unlockTime = action.payload;
    },
    setStartTime: (state, action: PayloadAction<number>) => {
      const key = state.activeContract;
      state.list[key].startTime = action.payload;
    },
    setFinalized: (state, action: PayloadAction<boolean>) => {
      const key = state.activeContract;
      state.list[key].finalized = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setContract,
  setContractData,
  setStakers,
  setTotalFundsCommited,
  setUserInfo,
  setRewardAmount,
  setUnlockTime,
  setStartTime,
  setFinalized
} = contractSlice.actions;

export default contractSlice.reducer;
