import React, { useEffect } from 'react';
import './App.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { useWeb3React } from '@web3-react/core';

import { useSelector } from 'react-redux';
import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import { Home } from 'pages/Home';
import { RootState } from 'store';
import { useDexiStakeContract } from 'hooks/useDexiStakeContract';
import { useAppStore } from 'hooks/useAppStore';

function App() {
  //const activeWallet: Wallet = useSelector((state: IRootState) => state.wallet.activeWallet);
  const { account } = useWeb3React();
  const { stakers } = useAppStore();
  useDexiStakeContract();

/*   useEffect(() => {
    if (account && !stakers) {
      fetchDexiInfo().catch((err) => console.error(`Failed to fetch stakers: ${err.stack}`));
    }
  }, [account, fetchDexiInfo, stakers]); */

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
