import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { injectedConnector, networkConnector, walletConnector } from 'helpers/connectors';
import usePromptNetwork from './useNetworkPrompt';

let connector = null;
connector = networkConnector;

const useAuth = () => {
  const { library, account, chainId, activate, deactivate } = useWeb3React();
  const [keepConnection, setKeepConnection] = useState(localStorage.getItem('DexiStakeConnect') == 'KEEP');
  useEffect(async () => {
    if (localStorage.getItem('DexiStakeConnect') == 'KEEP') {
      console.log(localStorage.getItem('DexiStakeConnect'));
      if (await injectedConnector.isAuthorized()) {
        await activate(injectedConnector);
      } else if(walletConnector.isAuthorized()){
        await activate(walletConnector);
      }
    }
  }, [account, keepConnection]);

  const login = useCallback(
    async (walletId = 0) => {
      if (walletId === 1) {
        connector = injectedConnector;
      } else if (walletId === 2) {
        connector = walletConnector;
      }
      localStorage.setItem('DexiStakeConnect', 'KEEP');
      setKeepConnection(localStorage.setItem('DexiStakeConnect', 'KEEP'));

      console.log(localStorage.getItem('DexiStakeConnect'));
      await activate(connector);
    },
    [activate, library, chainId],
  );

  const logout = useCallback(() => {
    localStorage.setItem('DexiStakeConnect', 'DISCONNECT');
    setKeepConnection(localStorage.setItem('DexiStakeConnect', 'DISCONNECT'));
    console.log('DISCONNECT', localStorage.getItem('DexiStakeConnect'));
    deactivate();
  }, [deactivate]);

  return { login, logout };
};

export default useAuth;
