import { useWeb3React } from '@web3-react/core';
import useApprove, { ApprovalState } from 'hooks/useApprove';
import { useAppStore } from 'hooks/useAppStore';
import { FC, useEffect, useMemo, useState } from 'react';
import { claim, commit, getContract, getDexi } from 'services/dexiStakeService';
import { useMediaQuery } from 'usehooks-ts';
import { PadInput, PadMainButton } from './ui';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import Countdown from 'react-countdown';
import { dexigov, group2639, path119, path139, path239, path819 } from './ui/PathImg';

interface IPadCard {
  stakeToken: string;
}

enum MODE {
  COMMIT = 'COMMIT',
  CLAIM = 'CLAIM',
  WAIT = 'WAIT',
}

export const PadCard: FC<IPadCard> = (props) => {
  const { stakeToken } = props;

  const isDesktop = useMediaQuery('(min-width:800px)');
  const { account, library } = useWeb3React();
  const { contractName } = useAppStore();
  const [approveStatus, approve] = useApprove(
    library ? getDexi(library.getSigner(account)) : null,
    getContract(contractName)?.address,
  );

  const [amount, setAmount] = useState(0);

  const [mode, setMode] = useState<MODE>(MODE.COMMIT);

  const { ownBalance, userInfo, unlockTime, startTime, rewardAmount, finalized } = useAppStore();

  const unlockDateMemo = useMemo(() => {
    console.log(unlockTime);
    if (unlockTime && unlockTime > 0) {
      const date = new Date(0);
      date.setUTCSeconds((unlockTime ?? 0).valueOf());
      return date;
    } else {
      return null;
    }
  }, [userInfo]);

  const startTimeMemo = useMemo(() => {
    console.log(startTime);
    if (startTime && startTime > 0) {
      const date = new Date(0);
      date.setUTCSeconds((startTime ?? 0).valueOf());
      return date;
    } else {
      return null;
    }
  }, [userInfo]);

  useEffect(() => {
    if ((unlockTime ? unlockTime : 0) < Date.now() / 1000 && (rewardAmount ?? 0) > 0) {
      setMode(MODE.CLAIM);
      setAmount(rewardAmount ?? 0);
    } else if (
      !finalized &&
      startTime &&
      startTime < Date.now() / 1000 &&
      unlockTime &&
      unlockTime > Date.now() / 1000
    ) {
      setMode(MODE.COMMIT);
    } else {
      setMode(MODE.WAIT);
    }
  }, [unlockTime]);

  if (isDesktop) {
    return (
      <div className="overlap-group6">
        <div className="overlap-group-4">
          <div className="rectangle-18-1"></div>
          <img className="path-1" src={path119} alt="Path 1" />
          <div className="rectangle-367"></div>
          <img className="path-8" src={path819} alt="Path 8" />
        </div>
        <div className="time">
          <div className="flex-col">
            <div className="flex-row">
              <div className="amount-to-stake oxanium-normal-white-20px">Committed Dexi</div>
              <div className="available-balance-200000-dexi oxanium-normal-white-18px">
                {`${userInfo && userInfo.amount != null ? userInfo.amount : '--'} ${stakeToken}`}
              </div>
            </div>

            <div className="flex-row">
              <div className="amount-to-stake oxanium-normal-white-20px">Available Balance</div>
              <div className="available-balance-200000-dexi oxanium-normal-white-18px">
                <>
                  {ownBalance ?? '--'} {props.stakeToken}
                </>
              </div>
            </div>
            <div className="flex-row oxanium-bold-white-20px">
              <div className="amount-to-stake oxanium-normal-white-20px">Tokens bought</div>
              <div className="available-balance-200000-dexi oxanium-normal-white-20px">
                {' '}
                {`${rewardAmount ?? '--'} DEXIGOV`}
              </div>
            </div>
          </div>
        </div>
        <div className="amount">
          <div className="description oxanium-normal-white-20px">
            <a href="https://www.dexioprotocol.com/dexigov">
              Learn more about DexiGov <BsBoxArrowUpRight />
            </a>
          </div>

          <PadInput disabled={mode != MODE.COMMIT} value={amount} onChange={(v) => setAmount(Number(v))} />
        </div>
        <div className="logogov">
          <img className="dexigov" src={dexigov} alt="DEXIGOV" />
        </div>
        <div className="dexiusd">
          {!unlockTime || !startTime ? (
            ''
          ) : startTime && Date.now() / 1000 < startTime ? (
            <div className="flex-col description">
              <div className="timeInfo oxanium-bold-white-18px">{`Launchpad Starts`}</div>
              <div className="timeInfo oxanium-bold-white-18px">{`${startTimeMemo?.toLocaleDateString()} ${startTimeMemo?.toLocaleTimeString()}`}</div>
            </div>
          ) : unlockTime < Date.now() / 1000 ? (
            <div className="flex-col description">
              <div className="timeInfo oxanium-bold-white-18px">{`Launchpad Closed!`}</div>
              <div className="timeInfo oxanium-bold-white-18px">
                {finalized ? `You can claim your DEXIGov!` : `DEXIGov will be claimable soon!`}
              </div>
            </div>
          ) : (
            <div className="flex-col description">
              <div className="timeInfo oxanium-bold-white-18px">{`End date:: ${unlockDateMemo?.toLocaleDateString()} ${unlockDateMemo?.toLocaleTimeString()}`}</div>
              <div className="timeInfo oxanium-bold-white-18px">
                {unlockTime > Date.now() / 1000 ? (
                  <Countdown className="oxanium-bold-white-18px" date={unlockTime * 1000} />
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
        </div>

        {mode === MODE.COMMIT ? (
          approveStatus !== ApprovalState.APPROVED ? (
            <PadMainButton onClick={approve}>Approve {stakeToken}</PadMainButton>
          ) : (
            <PadMainButton
              onClick={() => {
                commit(amount, library.getSigner(account));
              }}
            >
              Commit {stakeToken}
            </PadMainButton>
          )
        ) : mode === MODE.CLAIM && finalized ? (
          <PadMainButton
            onClick={() => {
              claim(library.getSigner(account));
            }}
          >
            Claim
          </PadMainButton>
        ) : (
          ''
        )}

        <div className="sides">
          <div className="ellipse-1-3"></div>
          <div className="ellipse-2-4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="group-container-37">
      <div className="group-13">
        <div className="group-13">
          <div className="group-3-33">
            <div className="overlap-group-55">
              <img className="path-1-12" src={path139} alt="Path 1" />
              <div className="rectangle-367-6"></div>
              <img className="path-2-1" src={path239} alt="Path 2" />
            </div>
          </div>
          <div className="group-26-3" style={{ backgroundImage: `url(${group2639})` }}>
            <div className="ellipse-1-40"></div>
            <div className="ellipse-2-47"></div>
          </div>
        </div>
        <div className="logogov-mobile">
          <img className="dexigov" src={dexigov} alt="DEXIGOV" />
        </div>
        <div className="group-16-4">
          {!unlockTime || !startTime ? (
            ''
          ) : startTime && Date.now() / 1000 < startTime ? (
            <div className="flex-col description">
              <div className="time-info-mobile oxanium-normal-white-16px">{`Launchpad Starts`}</div>
              <div className="time-info-mobile oxanium-normal-white-16px">{`${startTimeMemo?.toLocaleDateString()} ${startTimeMemo?.toLocaleTimeString()}`}</div>
            </div>
          ) : unlockTime < Date.now() / 1000 ? (
            <div className="flex-col description">
              <div className="time-info-mobile oxanium-normal-white-16px">{`Launchpad Closed!`}</div>
              <div className="timeInfo oxanium-bold-white-18px">
                {finalized ? `You can claim your DEXIGov!` : `DEXIGov will be claimable soon!`}
              </div>
            </div>
          ) : (
            <div className="flex-col description">
              <div className="time-info-mobile oxanium-bold-white-16px">
                <span>
                  Ends {unlockDateMemo?.toLocaleDateString()} {unlockDateMemo?.toLocaleTimeString()}
                </span>
              </div>
              <div className="time-info-mobile oxanium-bold-white-16px">
                {unlockTime > Date.now() / 1000 ? (
                  <Countdown className="oxanium-bold-white-18px" date={unlockTime * 1000} />
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
          <div className="description oxanium-normal-white-16px">
            <a href="https://www.dexioprotocol.com/dexigov">
              Learn more about DexiGov <BsBoxArrowUpRight />
            </a>
          </div>
          <PadInput disabled={mode != MODE.COMMIT} value={amount} onChange={(v) => setAmount(Number(v))} />
          <div className="flex-col available-balance-200000-dexi-4 ">
            <div className="flex-row available-balance-200000-dexi-4">
              <div className="oxanium-normal-white-13px">Committed Dexi:</div>{' '}
              <div className="oxanium-normal-white-13px">
                {' '}
                {`${userInfo && userInfo.amount != null ? userInfo.amount : '--'} ${stakeToken}`}
              </div>
            </div>
            <div className="flex-row available-balance-200000-dexi-4">
              <div className="oxanium-normal-white-13px">Available Balance:</div>{' '}
              <div className="oxanium-normal-white-13px">{`${ownBalance ?? '--'} ${stakeToken}`}</div>
            </div>
            <div className="flex-row available-balance-200000-dexi-4">
              <div className="oxanium-normal-white-13px">Tokens bought</div>
              <div className="oxanium-normal-white-13px"> {`${rewardAmount ?? '--'} DEXIGOV`}</div>
            </div>
          </div>
        </div>
        {mode === MODE.COMMIT ? (
          approveStatus !== ApprovalState.APPROVED ? (
            <PadMainButton onClick={approve}>Approve {stakeToken}</PadMainButton>
          ) : (
            <PadMainButton
              onClick={() => {
                commit(amount, library.getSigner(account));
              }}
            >
              Commit {stakeToken}
            </PadMainButton>
          )
        ) : mode === MODE.CLAIM && finalized ? (
          <PadMainButton
            onClick={() => {
              claim(library.getSigner(account));
            }}
          >
            Claim
          </PadMainButton>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
