import 'ethers';
import { BigNumber, Contract, Signer } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import erc20 from '../abis/erc20.json';
import dexiGovSale from '../abis/dexiGovSale.json';
import { Web3Provider } from '@ethersproject/providers';
import ERC20 from './ERC20';
import Web3 from 'web3';
import { getDisplayBalance } from 'helpers/tokenUtils';

interface Contracts {
  [key: string]: Contract;
}

interface UserInfoLaunchpad {
  amount: BigNumber;
  claimedTokens: BigNumber;
}

const DexiAddress = '0x65ba64899c2c7dbfdb5130e42e2cc56de281c78b';
const USDCAddress = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';

const getUsdc = (provider: Web3Provider | Signer) => {
  return new ERC20(USDCAddress, provider, 'USDC', 6);
};
const getDexi = (provider: Web3Provider | Signer) => {
  return new ERC20(DexiAddress, provider, 'DEXI', 9);
};
const contracts: Contracts = {
  DEXI: new Contract(DexiAddress, erc20.abi),
  USDC: new Contract(USDCAddress, erc20.abi),
  DEXIGOVPAD: new Contract(dexiGovSale.address, dexiGovSale.abi),
};

function getContract(contractName: string) {
  return contracts[contractName];
}

const getDexiBalance = async (address: string, provider: Web3Provider) => {
  const contract = contracts['DEXI'].connect(provider);
  console.log(provider);
  const result = await contract.balanceOf(address);
  console.log(result);
  return result;
};

const getUsdcBalance = async (address: string, provider: Web3Provider) => {
  const contract = contracts['USDC'].connect(provider);
  const result = await contract.balanceOf(address);
  return result;
};

const getTokenBalance = async (tokenAddress: string, address: string, provider: Web3Provider) => {
  const contract = new Contract(tokenAddress, erc20.abi).connect(provider);
  console.log(provider);
  const result = await contract.balanceOf(address);
  console.log(result);
  return result;
};

const getUserInfoPad = async (address: string, provider: Web3Provider): Promise<UserInfoLaunchpad> => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.userInfo(address);
  return result;
};

const commit = async (amount: number, provider: Web3Provider) => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.deposit(BigNumber.from(amount * 1e9));
  return result;
};

const claim = async (provider: Web3Provider) => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.harvestTokens();
  return result;
};

const getTotalUsers = async (provider: Web3Provider): Promise<BigNumber> => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.getAddressListLength();
  return result;
};

const getTotalCommited = async (provider: Web3Provider): Promise<BigNumber> => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.totalAmount();
  return result;
};

const getRewardAmount = async (user: string, provider: Web3Provider): Promise<BigNumber> => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.getOfferingAmount(user);
  console.log("Contract Response", Number(result));
  return result;
};

const getEndTime = async (provider: Web3Provider): Promise<BigNumber> => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.endTime();
  return result;
};
const getStartTime = async (provider: Web3Provider): Promise<BigNumber> => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.startTime();
  return result;
};
const getFinalized = async (provider: Web3Provider): Promise<boolean> => {
  const contract = contracts['DEXIGOVPAD'].connect(provider);
  const result = await contract.finalized();
  return result;
};

export type { UserInfoLaunchpad };
export {
  DexiAddress,
  USDCAddress,
  getContract,
  getUsdc,
  getDexi,
  getDexiBalance,
  getUsdcBalance,
  getTokenBalance,
  getUserInfoPad,
  commit,
  claim,
  getTotalUsers,
  getTotalCommited,
  getRewardAmount,
  getEndTime,
  getStartTime,
  getFinalized
};
