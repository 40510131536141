import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useAppStore = () => {
  const { activeContract } = useSelector((state: RootState) => state.contract);
  const {
    contractName,
    unlockTime,
    startTime,
    ownBalance,
    stakers,
    tokenName,
    userInfo,
    totalCommitted,
    rewardAmount,
    finalized,
  } = useSelector((state: RootState) => state.contract.list[state.contract.activeContract]);
  return {
    contractName: contractName ? contractName : '',
    unlockTime,
    startTime,
    ownBalance,
    stakers,
    tokenName,
    userInfo,
    totalCommitted,
    rewardAmount,
    activeContract,
    finalized
  };
};
