import { NetworkConnector } from '@web3-react/network-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
export const injectedConnector = new InjectedConnector({
  // supportedChainIds: [parseInt(currentNetwork)],
});

export const networkConnector = new NetworkConnector({
  urls: { 137: process.env.REACT_APP_CHAIN_URL },
  defaultChainId: 137,
});

export const walletConnector = new WalletConnectConnector({
  rpc: { 137: process.env.REACT_APP_CHAIN_URL },
  qrcode: true,
  chainId: 137,
});
